import useTitleSection from "@/hooks/common/titles/useTitleSection";

type Props = {
    title: string | undefined;
    newClass?: string;
};
function TitleSection({ title, newClass }: Props) {
    const { firstWord, secondWord } = useTitleSection(title || "");

    return (
        <h2 className={`title-section font-vip ${newClass}`}>
            {firstWord} <span>{secondWord}</span>
        </h2>
    );
}

export default TitleSection;

"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { userCountry: string | null } = {
    userCountry: null,
};

const userCountrySlice = createSlice({
    name: "userCountrySlice",
    initialState,
    reducers: {
        updateUserCountry: (state, action: PayloadAction<string | null>) => {
            state.userCountry = action.payload;
        },
    },
});

const userCountryReducer = userCountrySlice.reducer;

export const { updateUserCountry } = userCountrySlice.actions;
export default userCountryReducer;

"use client";

import { apiCoursesSlice } from "@/redux/app/api/apiSlice";

export const coursesApiSlice = apiCoursesSlice.injectEndpoints({
    endpoints: (builder) => ({
        // anonymous
        getAllCourses: builder.query({
            query: (params) => ({
                url: `/api/v1/courses`,
                params: { ...params },
            }),
            providesTags: ["Courses"],
        }),
        getCourse: builder.query({
            query: (courseId) => ({
                url: `/api/v1/courses/${courseId}`,
            }),
            providesTags: ["Courses"],
        }),
        // admin
        getListCourses: builder.query({
            query: (params) => ({
                url: `/api/v1/dashboard/courses/`,
                params: { ...params },
            }),
            providesTags: ["Courses"],
        }),
        getSpecificCourses: builder.query({
            query: (courseId) => ({
                url: `/api/v1/dashboard/courses/${courseId}`,
            }),
            providesTags: ["Courses"],
        }),
        getStatisticsCourses: builder.query({
            query: (params) => ({
                url: `/api/v1/dashboard/statistics/courses`,
                params: { ...params },
            }),
            providesTags: ["Courses"],
        }),
        getTopBestSellingCourses: builder.query({
            query: (params) => ({
                url: `/api/v1/dashboard/statistics/courses/best-selling`,
                params: { ...params },
            }),
            providesTags: ["Courses"],
        }),
        createCourses: builder.mutation({
            query: (coursesItem) => ({
                url: "/api/v1/dashboard/courses",
                method: "POST",
                body: { ...coursesItem },
            }),
            invalidatesTags: ["Courses"],
        }),
        updateCourse: builder.mutation({
            query: ({ courseId, coursesItem }) => ({
                url: `/api/v1/dashboard/courses/${courseId}`,
                method: "PUT",
                body: { ...coursesItem },
            }),
            invalidatesTags: ["Courses"],
        }),
        successUploadCourses: builder.mutation({
            query: ({ courseId, coursesItem }) => ({
                url: `/api/v1/dashboard/courses/${courseId}/success-upload`,
                method: "POST",
                body: { ...coursesItem },
            }),
            invalidatesTags: ["Courses"],
        }),
        deleteCourse: builder.mutation({
            query: (courseId) => ({
                url: `/api/v1/dashboard/courses/${courseId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Courses"],
        }),
        deleteCourses: builder.mutation({
            query: (coursesIDs) => ({
                url: `/api/v1/dashboard/courses`,
                method: "DELETE",
                body: { coursesIDs },
            }),
            invalidatesTags: ["Courses"],
        }),
        // CategoriesId/Courses
        getAllFromSpecificCategory: builder.query({
            query: ({ categoryId, params }) => ({
                url: `/api/v1/categories/${categoryId}/courses`,
                // params: { ...params },
            }),
            providesTags: ["Courses"],
        }),
        // categoriesId/SubcategoriesId/Courses
        getAllFromSpecificSubcategory: builder.query({
            query: ({ categoriesId, subCategoryId, params }) => ({
                url: `/api/v1/categories/${categoriesId}/${subCategoryId}/courses`,
                params: { ...params },
            }),
            providesTags: ["Courses"],
        }),
    }),
});

export const {
    useGetAllCoursesQuery,
    useLazyGetAllCoursesQuery,
    useGetCourseQuery,
    useGetListCoursesQuery,
    useLazyGetListCoursesQuery,
    useGetSpecificCoursesQuery,
    useGetStatisticsCoursesQuery,
    useGetTopBestSellingCoursesQuery,
    useCreateCoursesMutation,
    useUpdateCourseMutation,
    useSuccessUploadCoursesMutation,
    useDeleteCourseMutation,
    useDeleteCoursesMutation,
    useGetAllFromSpecificCategoryQuery,
    useLazyGetAllFromSpecificCategoryQuery,
    useGetAllFromSpecificSubcategoryQuery,
    useLazyGetAllFromSpecificSubcategoryQuery,
} = coursesApiSlice;

"use client"
import { useDeleteLoggedReviewMutation } from "@/redux/features/api/reviews/reviewsApiSlice";
import { useState } from "react";
import toast from "react-hot-toast";

const useDeleteCourseRating = (setMyReviewId: any) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const [deleteLoggedReview, { isLoading: isLoadingDelete }] = useDeleteLoggedReviewMutation();
    const handleModalConfirm = async (id: string) => {
        try {
            const resData = await deleteLoggedReview(id).unwrap();
            setMyReviewId(undefined);
            toast.success("تمت عملية المسح بنجاح");
        } catch (err) {
            toast.error("فشلت عملية المسح")
        }
    }

    return {
        isModalOpen, setModalOpen, handleModalConfirm, isLoadingDelete
    }
}

export default useDeleteCourseRating;
type Props = {
    children?: string;
};

function TextError({ children }: Props) {
    return (
        <div className="form-error-msg" title={children}>
            {children}
        </div>
    );
}

export default TextError;

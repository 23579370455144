import { customStylesModal } from '@/utils/reactModelCustomStyle/reactModalCustomStyle';
import Modal from 'react-modal';
import LoadingEffect from '../loading/LoadingEffect';

type Props = {
    isModalOpen: boolean;
    setModalOpen: (arg: boolean) => void;
    confirmMethod: any;
    contentLabel: string;
    msg: string;
    textConfirm: string;
    isLoading?: boolean;
}

Modal.setAppElement('#app');

const CustomModal = ({ isLoading, isModalOpen, setModalOpen, confirmMethod, contentLabel, msg, textConfirm }: Props) => {
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            contentLabel={contentLabel}
            style={customStylesModal}
        >
            <p className="modal-msg">{msg}</p>
            <div className="btn-modal-box">
                <button type="button" className={'save solid-common-btn'} disabled={isLoading} onClick={() => { !isLoading && confirmMethod() }}>{isLoading ? <LoadingEffect /> : textConfirm}</button>
                <button type="button" className={'cancel solid-common-btn'} onClick={() => setModalOpen(false)}>إلغاء</button>
            </div>
        </Modal>
    );
}

export default CustomModal;
"use client"
import { useState, useRef, useEffect } from "react";

function useContentUnit(gap: number) {
  const [unitStatus, setUnitStatus] = useState(false);
  const [openHeight, setOpenHeight] = useState("0px");
  const Element: any = useRef();

  useEffect(() => {
    if (Element?.current?.children) {
      const sumHeight = [...Element?.current?.children].reduce((acc, el, inx) => {
        return acc + Number(el?.scrollHeight) + gap
      }, 0);
      setOpenHeight(`${sumHeight}px`);
    }
  }, [Element?.current?.children, gap, unitStatus]);

  const handleCollapse = () => {
    unitStatus ? setUnitStatus(false) : setUnitStatus(true);
  }
  return { unitStatus, openHeight, Element, handleCollapse };
}

export default useContentUnit;

const commonStyleContent = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    minWidth: "352px",
};

export const customStylesModal = {
    content: commonStyleContent,
};

export const customStylesModalViewOrder = {
    content: {
        ...commonStyleContent,
        padding: "15px",
    },
};

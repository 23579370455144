"use client"
import { useState } from "react";

const useEditModalLoggedRating = () => {
    const [isModalOpenEdit, setModalOpenEdit] = useState(false);

    return {
        isModalOpenEdit, setModalOpenEdit
    }
}

export default useEditModalLoggedRating;
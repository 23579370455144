"use client";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import getErrorMsg from "@/utils/auth/errorMsg/getErrorMsg";
import { useUpdateLoggedReviewMutation } from "@/redux/features/api/reviews/reviewsApiSlice";
import toast from "react-hot-toast";

interface FormValues {
    ratingNumber?: number;
    ratingText: string;
}

function useEditRating(courseId: string, setModalOpenEdit: any, quote: string, rating: number) {
    const [ratingNumberValue, setRatingNumberValue] = useState<string>("");

    useEffect(() => {
        setRatingNumberValue(rating.toFixed(1));
    }, [setModalOpenEdit, rating]);

    const handleRateChange = (rateValue: number) => {
        setRatingNumberValue(`(${rateValue.toFixed(1)})`);
    }
    const [errServerMsg, setErrServerMsg] = useState<string | []>("");
    const errRef = useRef<HTMLDivElement>(null);

    const initialValues: FormValues = {
        ratingNumber: rating || 0,
        ratingText: quote || "",
    };
    const [updateLoggedReview, { isLoading }] = useUpdateLoggedReviewMutation();

    const validationSchema = Yup.object({
        ratingText: Yup.string()
            .required("نص التقييم مطلوب"),
    });

    const handleSubmit = async (values: FormValues, submitActions: any) => {
        if (ratingNumberValue === "(0.0)" || "") {
            setErrServerMsg("التقييم يجب أن يكون أكبر من صفر");
        } else {
            try {
                const sendValues = { ...values, ratingNumber: ratingNumberValue };
                const resData = await updateLoggedReview({ courseId, rating: Number(sendValues.ratingNumber.slice(1, -1)), comment: sendValues.ratingText }).unwrap();
                submitActions.resetForm();
                setErrServerMsg("");
                toast.success("تم تعديل تقييمك بنجاح..شكرا لك")
                setModalOpenEdit(false);
            } catch (err) {
                const errMsg = getErrorMsg(err, "فشل في تعديل التقييم");
                setErrServerMsg(errMsg);
                errRef.current?.focus();
            }
        }
        submitActions.setSubmitting(false);
        submitActions.resetForm();
    };
    return {
        initialValues,
        validationSchema,
        handleSubmit,
        errRef,
        errServerMsg,
        isLoading,
        handleRateChange,
        ratingNumberValue
    };
}

export default useEditRating;

import ReactStars from "@/components/UI/common/reactStare/ReactStars";
import Image from "next/image";
import avatarUserImg from "@/../public/images/avatur-user2.png";
import { GoPencil } from "react-icons/go";
import { FaTrash } from "react-icons/fa";
import CustomModal from "../modal/CustomModal";
import useDeleteCourseRating from "@/hooks/pages/courses/coursePageSections/courseRatings/useDeleteCourseRating";
import Modal from 'react-modal';
import { customStylesModal } from "@/utils/reactModelCustomStyle/reactModalCustomStyle";
import EditRating from "./EditRating";
import useEditModalLoggedRating from "@/hooks/pages/courses/coursePageSections/courseRatings/useEditModalLoggedRating";
import { useState } from "react";
import useContentUnit from "@/hooks/pages/courses/coursePageSections/courseContents/units/useContentUnit";

type Props = {
    id: string;
    name: string;
    description: string;
    quote: string;
    rating: number;
    image?: string;
    isMyOwn?: boolean;
    courseId?: string;
    setMyReviewId?: any;
    cursorGrab?: boolean;
};

Modal.setAppElement('#app');

function OpinionsCard({ id, name, description, quote, rating, image, isMyOwn, courseId, setMyReviewId, cursorGrab }: Props) {
    const { isModalOpen, setModalOpen, handleModalConfirm, isLoadingDelete } = useDeleteCourseRating(setMyReviewId);
    const { isModalOpenEdit, setModalOpenEdit } = useEditModalLoggedRating();
    const { unitStatus, openHeight, Element, handleCollapse } = useContentUnit(5);

    // ${cursorGrab ? "cursor-grab" : ""}
    return (
        <div className={`opinions-card ${unitStatus ? "active" : ""} `} dir="rtl">
            <div className="opinions-card-top">
                <div className="opinions-card-top-left">
                    <div className="hand-image-opinion">
                        <Image src={image || avatarUserImg} alt="student" width={400} height={400} />
                    </div>
                    <div className="header-opinion-card">
                        <p className="opinions-card-student-name">{name}</p>
                        <span className="opinions-card-student-description">
                            {description}
                        </span>
                    </div>
                </div>
                <ReactStars
                    value={rating}
                    edit={false}
                    count={5}
                    size={24}
                    color1={"#a3a2a2"}
                    color2={"#ffd700"}
                    className={"opinions-card-top-right opinions-rate"}
                />
            </div>
            <p className={`opinions-card-bottom ${unitStatus && "active"} `} style={{ height: unitStatus ? openHeight : "56px" }} title={quote} ref={Element}>
                <span>{quote}</span>
            </p>
            {/* 93 */}
            {quote.length >= 90 && <button className="btn-more-opinion" onClick={handleCollapse}>{unitStatus ? "عرض الأقل" : "عرض المزيد"}</button>}
            {isMyOwn && courseId &&
                <div className="opinions-card-action-box">
                    <GoPencil size={20} className="opinions-card-edit-icon" onClick={() => setModalOpenEdit(true)} />
                    <Modal
                        isOpen={isModalOpenEdit}
                        onRequestClose={() => setModalOpenEdit(false)}
                        contentLabel={"تعديل التقييم"}
                        style={customStylesModal}
                    >
                        <div className="edit-review-logged-user" id="edit-review-logged-user">
                            <div className="add rating-card">
                                <EditRating courseId={courseId} setModalOpenEdit={setModalOpenEdit} quote={quote} rating={rating} />
                            </div>
                        </div>
                    </Modal>
                    <FaTrash size={20} className={`opinions-card-trash-icon`} onClick={() => setModalOpen(true)} />
                    <CustomModal isLoading={isLoadingDelete} isModalOpen={isModalOpen} setModalOpen={setModalOpen} confirmMethod={() => handleModalConfirm(id)} contentLabel={"هل أنت متأكد من حذف تقييمك علي هذه الدورة التعليمية؟"} msg={"هل أنت متأكد من حذف تقييمك علي هذه الدورة التعليمية؟"} textConfirm={"تأكيد"} />
                </div>
            }
        </div>
    );
}

export default OpinionsCard;

"use client"
import ReactStars from "@/components/UI/common/reactStare/ReactStars";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import SubmitBtn from "../buttonsOrLinks/SubmitBtn";
import TextError from "../textError/TextError";
import ServerError from "../textError/ServerError";
import useEditRating from "@/hooks/common/useEditRating";

type Params = {
    courseId: string;
    setModalOpenEdit: any;
    quote: string;
    rating: number;
}
function EditRating({ courseId, setModalOpenEdit, quote, rating }: Params) {
    const {
        initialValues,
        validationSchema,
        handleSubmit,
        errRef,
        errServerMsg,
        isLoading,
        handleRateChange,
        ratingNumberValue
    } = useEditRating(courseId, setModalOpenEdit, quote, rating);

    return (
        <div className="opinions-card opinions-card-add-rating">
            <div className="opinions-card-top-add-rating">
                <ReactStars
                    value={rating}
                    edit={true}
                    count={5}
                    size={45}
                    dir={"rtl"}
                    color1={"#a3a2a2"}
                    color2={"#ffd700"}
                    className={"opinions-card-top-right opinions-add-rate"}
                    onChange={handleRateChange}
                />
                <span className="rating-number-text">{ratingNumberValue === "(0.0)" ? "" : ratingNumberValue}</span>
            </div>
            {errServerMsg && <ServerError errServerMsg={errServerMsg} errRef={errRef} />}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik: FormikProps<any>) => (
                    <Form>
                        <div className="form-middle">
                            <div className="field-box ">
                                <div className="field-wrapper">
                                    <Field
                                        as="textarea"
                                        type="text"
                                        id="ratingText"
                                        name="ratingText"
                                        aria-label="عدل تقييمك علي الدورة التعليمية"
                                        placeholder="عدل تقييمك علي الدورة التعليمية"
                                    />
                                </div>
                                <ErrorMessage
                                    name="ratingText"
                                    component={TextError}
                                />
                            </div>
                        </div>
                        <div className="btn-review-box">
                            <SubmitBtn
                                isLoading={isLoading}
                                formik={formik}
                                className="btn-edit-review"
                                text={"تأكيد"}
                            />
                            <button type="button" className='cancel solid-common-btn btn-edit-review' onClick={() => setModalOpenEdit(false)}>إلغاء</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default EditRating;

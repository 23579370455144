import { useAppDispatch } from "@/redux/app/hooks";
import { useGetLoggedProfileQuery } from "@/redux/features/api/loggedUser/loggedUserApiSlice";
import { updateUserCountry } from "@/redux/features/local/userCountry/userCountrySlice";
import Cookies from "js-cookie";
import { useEffect } from "react";

const useCountryUser = () => {
    const { data: dataUser } = useGetLoggedProfileQuery({}, { skip: !Cookies.get("loggedIn") });
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (Cookies.get("loggedIn")) {
            dataUser?.data?.profile?.country && dispatch(updateUserCountry(dataUser?.data?.profile?.country))
        }
    }, [dataUser]);

    return {
        userCountry: dataUser?.data?.profile?.country
    }
}

export default useCountryUser;
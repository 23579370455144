import { apiLoggedUserSlice } from "@/redux/app/api/apiSlice";

export const loggedUserApiSlice = apiLoggedUserSlice.injectEndpoints({
    endpoints: (builder) => ({
        //logged
        getLoggedProfile: builder.query({
            query: (params) => ({
                url: `/api/v1/users/profile`,
                params: { ...params },
            }),
        }),
        updateFristNameProfile: builder.mutation({
            query: (profileItem) => ({
                url: `/api/v1/users/first-name`,
                method: "PUT",
                body: { ...profileItem },
            }),
        }),
        updateLastNameProfile: builder.mutation({
            query: (profileItem) => ({
                url: `/api/v1/users/last-name`,
                method: "PUT",
                body: { ...profileItem },
            }),
        }),
        updateImageProfile: builder.mutation({
            query: (profileItem) => ({
                url: `/api/v1/users/change-profile`,
                method: "PUT",
                body: { ...profileItem },
            }),
        }),
        updateImageProfileSuccess: builder.mutation({
            query: (profileItem) => ({
                url: `/api/v1/users/change-profile-success`,
                method: "PUT",
                body: { ...profileItem },
            }),
        }),
        updatePasswordProfile: builder.mutation({
            query: (profileItem) => ({
                url: `/api/v1/users/change-password`,
                method: "PUT",
                body: { ...profileItem },
            }),
        }),
    }),
});

export const {
    useGetLoggedProfileQuery,
    useUpdateFristNameProfileMutation,
    useUpdateLastNameProfileMutation,
    useUpdatePasswordProfileMutation,
    useUpdateImageProfileMutation,
    useUpdateImageProfileSuccessMutation,
} = loggedUserApiSlice;

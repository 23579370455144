import LoadingEffect from "../loading/LoadingEffect";

type Props = {
    isLoading?: boolean;
    formik: any;
    text: string;
    className?: string;
    onClick?: any
    disable?: boolean;
};
function SubmitBtn({ isLoading, formik, text, className, onClick, disable }: Props) {
    return (
        <button
            type="submit"
            className={`solid-common-btn submit ${className}`}
            disabled={formik.isSubmitting || disable || isLoading}
            onClick={onClick}
        >
            {isLoading ? <LoadingEffect type="btn" /> : text}
        </button>
    );
}

export default SubmitBtn;


"use client";

import { apiReviewsSlice } from "@/redux/app/api/apiSlice";

export const reviewsApiSlice = apiReviewsSlice.injectEndpoints({
    endpoints: (builder) => ({
        // anonymous
        getAllReviews: builder.query({
            query: ({ courseId, params }) => ({
                url: `/api/v1/reviews/${courseId}`,
                params: { ...params },
            }),
            providesTags: ["Reviews"],
        }),
        getAllSpecial: builder.query({
            query: (params) => ({
                url: `/api/v1/reviews/special`,
                params: { ...params },
            }),
            providesTags: ["Reviews"],
        }),
        // logged && purchase
        getReviewLoggedUser: builder.query({
            query: (courseId) => ({
                url: `/api/v1/reviews/purchases/${courseId}`,
            }),
            providesTags: ["Reviews"],
        }),
        createReview: builder.mutation({
            query: (reviewsItem) => ({
                url: "/api/v1/reviews",
                method: "POST",
                body: { ...reviewsItem },
            }),
            invalidatesTags: ["Reviews"],
        }),
        updateLoggedReview: builder.mutation({
            query: (reviewItem) => ({
                url: `/api/v1/reviews`,
                method: "PUT",
                body: { ...reviewItem },
            }),
            invalidatesTags: ["Reviews"],
        }),
        deleteLoggedReview: builder.mutation({
            query: (reviewId) => ({
                url: `/api/v1/reviews/${reviewId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Reviews"],
        }),
        // admin
        getReviewsList: builder.query({
            query: ({ courseId, params }) => ({
                url: `/api/v1/dashboard/reviews/${courseId}`,
                params: { ...params },
            }),
            providesTags: ["Reviews"],
        }),
        getAllSpecialReviewCourse: builder.query({
            query: (courseId) => ({
                url: `/api/v1/dashboard/reviews/${courseId}/special`,
            }),
            providesTags: ["Reviews"],
        }),
        updateSpecificReview: builder.mutation({
            query: ({ reviewId, reviewItem }) => ({
                url: `/api/v1/dashboard/reviews/${reviewId}/special`,
                method: "PUT",
                body: { ...reviewItem },
            }),
            invalidatesTags: ["Reviews"],
        }),
        updateReview: builder.mutation({
            query: ({ reviewId, reviewsItem }) => ({
                url: `/api/v1/dashboard/reviews/${reviewId}`,
                method: "PUT",
                body: { ...reviewsItem },
            }),
            invalidatesTags: ["Reviews"],
        }),
        deleteReview: builder.mutation({
            query: (reviewId) => ({
                url: `/api/v1/dashboard/reviews/${reviewId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Reviews"],
        }),
        deleteReviews: builder.mutation({
            query: (reviewsIDs) => ({
                url: `/api/v1/dashboard/reviews`,
                method: "DELETE",
                body: { reviewsIDs },
            }),
            invalidatesTags: ["Reviews"],
        }),
    }),
});

export const {
    // anonymous
    useGetAllReviewsQuery,
    useLazyGetAllReviewsQuery,
    useGetAllSpecialQuery,
    // logged
    useGetReviewLoggedUserQuery,
    useCreateReviewMutation,
    useUpdateLoggedReviewMutation,
    useDeleteLoggedReviewMutation,
    // admin
    useGetReviewsListQuery,
    useGetAllSpecialReviewCourseQuery,
    useUpdateSpecificReviewMutation,
    useUpdateReviewMutation,
    useDeleteReviewMutation,
    useDeleteReviewsMutation,
} = reviewsApiSlice;

const getErrorMsg = (err: any, msgDefault: string): string | [] => {
    if (err?.status === "FETCH_ERROR") {
        return "فشل في ارسال طلب التحقق";
    } else if (err?.data?.error?.message) {
        return err?.data?.error?.message;
    } else if (err?.data?.errors) {
        const errorsMsgs = err?.data?.errors.map((el: any) => el.msg);
        return errorsMsgs;
    } else {
        return msgDefault;
    }
};

export default getErrorMsg;
